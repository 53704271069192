<template>
  <section id="calculation-special-items">
    <b-card>
      <b-row>
        <b-col xl="8">
          <b-form @submit.prevent="calculatePrices">
            <b-row v-for="(item, index) in inputItemValues" :key="index" class="align-items-center">
              <b-col xl="4">
                <b-form-group label="Item Name / Artikelname" :label-for="`name-${index}`">
                  <b-input-group class="input-group-merge"
                    :class="validationItem(item.name) ? 'is-valid' : 'is-invalid'">
                    <b-form-input :id="`name-${index}`" v-model="item.name" />
                  </b-input-group>
                  <b-form-invalid-feedback v-if="submitted && !validationItem(item.name)">
                    Empty string
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col xl="3">
                <b-form-group label="Amount / Menge" :label-for="`amount-${index}`">
                  <b-input-group class="input-group-merge"
                    :class="validationValue(item.amount) ? 'is-valid' : 'is-invalid'">
                    <b-form-input :id="`amount-${index}`" v-model="item.amount" type="number" />
                  </b-input-group>
                  <b-form-invalid-feedback v-if="submitted && !validationValue(item.amount)">
                    Only numbers greater than zero
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col xl="3">
                <b-form-group label="Purchase Price / Einkaufspreis" :label-for="`purchasePrice-${index}`">
                  <b-input-group class="input-group-merge"
                    :class="validationValue(item.purchasePrice) ? 'is-valid' : 'is-invalid'">
                    <b-form-input :id="`purchasePrice-${index}`" v-model="item.purchasePrice" type="number"
                      step="any" />
                  </b-input-group>
                  <b-form-invalid-feedback v-if="submitted && !validationValue(item.purchasePrice)">
                    Only numbers greater than zero
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col xl="2">
                <b-button v-if="index === inputItemValues.length - 1" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary" class="btn-block" :id="`addItem-${index}`" @click="addItem">
                  Add item
                </b-button>
              </b-col>
            </b-row>
            <br>
            <b-form-group label="Extra Costs / Sonder/Zusatzkosten" label-for="extraCosts">
              <b-input-group class="input-group-merge" :class="validationValue(extraCosts) ? 'is-valid' : 'is-invalid'">
                <b-form-input id="extraCosts" v-model="extraCosts" type="number" />
              </b-input-group>
              <b-form-invalid-feedback v-if="submitted" :state="validationValue(extraCosts)">
                Only numbers greater than zero
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Transport Costs / Transportkosten" label-for="transportCosts">
              <b-input-group class="input-group-merge"
                :class="validationValue(transportCosts) ? 'is-valid' : 'is-invalid'">
                <b-form-input id="transportCosts" v-model="transportCosts" type="number" />
              </b-input-group>
              <b-form-invalid-feedback v-if="submitted" :state="validationValue(transportCosts)">
                Only numbers greater than zero
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Notes / Notizen" label-for="notes">
              <b-form-textarea id="notes" v-model="notes" rows="4" />
            </b-form-group>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-block" type="submit">
              Calculate prices
            </b-button>
          </b-form>
          <br>
        </b-col>
        <b-col xl="4">
          <table class="table">
            <thead>
              <tr>
                <th><b class="text-secondary">Einkaufspreis</b></th>
                <th><b class="text-secondary">Faktor</b></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>&lt; 100</td>
                <td>3</td>
              </tr>
              <tr>
                <td>100-500</td>
                <td>2</td>
              </tr>
              <tr>
                <td>500-1000</td>
                <td>1.8</td>
              </tr>
              <tr>
                <td>&gt; 1000</td>
                <td>1.7</td>
              </tr>
            </tbody>
          </table>
          <br>
          <h6>SellingPrice = PurchasePrice * Factor + (TransportCostPerItem + ExtraCosts) / Amount</h6>
          <br>
          <b-row>
            <b-col xl="12" class="selling-price-container">
              <div>
                <h1 v-for="(item, index) in inputItemValues" :key="index">
                  {{ item.name }}: {{ item.sellingPrice }}
                </h1>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showSpecialProducts" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            History
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-history" />
          </h4>
          <b-popover target="popover-history" triggers="hover" placement="bottom">
            <span>The "Calculation Special Items" section includes input form fields for item name, amount, purchase
              price, extra costs, transport cost, and notes. After entering all values and clicking 'Calculate Prices,'
              the selling price is calculated using the formula: SellingPrice = PurchasePrice * Factor +
              (TransportCostPerItem + ExtraCosts) / Amount. You can add additional items by clicking the 'Add Item'
              button, which generates extra fields for item name, amount, and purchase price. A table with the history
              of calculations is also available.</span>
          </b-popover>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="specialProductsTable.currentPage"
          :items="specialProductsTable.items" :fields="specialProductsTable.fields"
          :sort-by.sync="specialProductsTable.sortBy" :sort-desc.sync="specialProductsTable.sortDesc"
          :sort-direction="specialProductsTable.sortDirection" :filter="specialProductsTable.filter"
          :filter-included-fields="specialProductsTable.filterOn" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="specialProductsTable.currentPage" :total-rows="specialProductsTable.totalRows"
              first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleSpecialProductsTablesPageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BOverlay,
  BCard,
  BTable,
  BPagination,
  BCardHeader,
  BPopover,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import axios from 'axios';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    BCard,
    BTable,
    BPagination,
    BCardHeader,
    BPopover,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showSpecialProducts: true,
      submitted: false,
      inputItemValues: [{ name: null, amount: null, purchasePrice: null, sellingPrice: null }],
      extraCosts: null,
      transportCosts: null,
      notes: null,
      queryParams: {},
      specialProductsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'created',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'name', label: 'name', sortable: true },
          {
            key: 'amount',
            label: 'amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'pprice',
            label: 'purchase price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'sprice',
            label: 'selling price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'extra_cost',
            label: 'extra cost',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'transport_cost',
            label: 'transport cost',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'notes', label: 'notes', sortable: true },
          {
            key: 'created',
            label: 'created at',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  async created() {
    try {
      await this.getSpecialProducts();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: 'BellIcon',
          variant,
        },
      })
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    validationItem(value) {
      return isNaN(value);
    },
    validationValue(value) {
      const number = parseFloat(value);
      return !isNaN(number) && isFinite(number) && number > 0;
    },
    addItem() {
      this.inputItemValues.push({ name: null, amount: null, purchasePrice: null, sellingPrice: null });
    },
    async calculatePrices() {
      this.submitted = true;
      const totalItems = this.inputItemValues.length;

      if (totalItems > 0) { // Ensure there are items to avoid division by zero
        const totalExtraCosts = parseFloat(this.extraCosts);
        const transportCosts = parseFloat(this.transportCosts);

        let totalShippingPerItem;
        if (totalItems == 1) {
          totalShippingPerItem = transportCosts;
        }
        else {
          totalShippingPerItem = transportCosts / totalItems + 2.0; // Total shipping cost divided by the total item count
        }

        // Calculate costs per item based on the amount, not just split by number of item types
        for (const item of this.inputItemValues) {
          if (item.name) {
            const purchasePrice = parseFloat(item.purchasePrice);
            const amount = parseInt(item.amount);

            let factor = 3.0;
            if (purchasePrice >= 100.0 && purchasePrice < 500.0) {
              factor = 2.0;
            } else if (purchasePrice >= 500.0 && purchasePrice < 1000.0) {
              factor = 1.8;
            } else if (purchasePrice >= 1000.0) {
              factor = 1.7;
            }

            // Update the selling price for each item
            item.sellingPrice = (purchasePrice * factor + (totalShippingPerItem + totalExtraCosts) / amount).toFixed(2);

            const params = {
              user: localStorage.getItem('useridLocal'),
              name: item.name,
              amount: amount,
              pprice: purchasePrice,
              sprice: item.sellingPrice,
              extra_cost: totalExtraCosts,
              transport_cost: totalShippingPerItem,
              notes: this.notes,
            };

            try {
              const response = await axios.post(`${process.env.VUE_APP_ROOT_API}/special-products/`, params, {
                headers: {
                  Authorization: `JWT ${this.$store.state.jwt}`,
                  'Content-Type': 'application/json',
                },
              });
              await this.getSpecialProducts();
            } catch (error) {
              if (error.response && error.response.status === 401) {
                this.$router.push({ name: 'login' });
              } else {
                // Handle other types of errors (e.g., network issues, server errors)
                console.error('An error occurred:', error);
              }
            }
            this.showToast("success", "The price was calculated");
          } else {
            this.showToast("warning", "Item name is empty.");
          }
        }
      } else {
        this.showToast("warning", "No items or zero total amount specified.");
      }
    },
    async getSpecialProducts() {
      this.showSpecialProducts = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/special-products/`, this.queryParams);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.specialProductsTable.items = results;
          this.specialProductsTable.totalRows = response.data.count * 2;
        }
        else {
          this.specialProductsTable.items = [];
          this.specialProductsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showSpecialProducts = false;
      }
    },
    async handleSpecialProductsTablesPageChange(value) {
      this.queryParams.page = value;
      await this.getSpecialProducts();
    },
  },
}
</script>

<style lang="scss">
.full-height-row {
  height: 100%;
}

.selling-price-container {
  flex: 1; // This allows the column to expand and fill the available space
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>